import {ArticleMatchPlayerFirestoreDocument} from "../../../../models";
import {Box, Stack, SvgIcon, Typography, useTheme} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {
	NkmbIconKartonpoln,
	NkmbIconSubstitutionIn,
	NkmbIconSubstitutionInOut,
	NkmbIconSubstitutionOut
} from "@nitra/nkmb-theme";
import React from "react";

export function ArticleMatchStripTeamLineup(props: {
	teamName: string,
	players: ArticleMatchPlayerFirestoreDocument[],
	isHome?: boolean,
	isFirstEleven?: boolean
}) {
	const {players, teamName, isHome, isFirstEleven} = props;

	const isMobile = useIsMobileContext()
	const theme = useTheme()

	const getLogo = (player: ArticleMatchPlayerFirestoreDocument) => {
		if (player.wasSubbedIn && player.wasSubbedOut) {
			return NkmbIconSubstitutionInOut
		} else if (player.wasSubbedIn) {
			return NkmbIconSubstitutionIn
		} else if (player.wasSubbedOut) {
			return NkmbIconSubstitutionOut
		} else {
			return NkmbIconKartonpoln
		}
	}

	return (
		<Stack alignItems={isHome ? "flex-end" : "flex-start"} sx={{width: "50%", px: "0px", mt: "20px"}}>
			<Typography variant={isMobile ? "h5" : "h4"} sx={{pb: 2, px: "12px"}}>{teamName}</Typography>
			{players
				.filter(player => player.isFirstEleven === isFirstEleven)
				.sort((a, b) => (a.jerseyNumber ?? 100) - (b.jerseyNumber ?? 100))
				.map(player =>
					<Stack sx={{
						flexDirection: isHome ? "row-reverse" : "row", py: "4px", flexGrow: 1,
						position: "relative",
						color: ((player.isFirstEleven && !player.wasSubbedOut) || (player.wasSubbedIn && !player.wasSubbedOut)) ? "unset" : "#676767"
					}}
					       alignItems={"center"}
					       key={player.key}>
						<Box sx={
							player.isHomeTeam ? {
									position: "absolute",
									top: -2,
									right: -6,
								} :
								{
									position: "absolute",
									top: -2,
									left: -6,
								}}>
							{(player.wasSubbedIn || player.wasSubbedOut) && <SvgIcon component={getLogo(player)}
                                                                                     sx={{
								                                                         width: 16,
								                                                         height: 16,
								                                                         color: "#000"
							                                                         }}
                                                                                     fill={"#000"}
                                                                                     viewBox={"0 0 100 100"}/>}
						</Box>
						<Box sx={{
							width: "40px",
							height: "40px",
							maxHeight: "40px",
							minWidth: "40px",
							backgroundColor: "#D9D9D9",
							backgroundImage: player.imageUrl ? `url(${player.imageUrl})` : "unset",
							borderRadius: "21px",
							border: "1px solid",
							borderColor: isHome ? theme.palette.primary.main : theme.palette.secondary.main,
						}}>

						</Box>
						<Box sx={{width: "6px"}}></Box>
						<Typography variant={"bodyM"}>{player.jerseyNumber}</Typography>
						<Box sx={{width: "6px"}}></Box>
						<Typography sx={{textAlign: isHome ? "right" : "left",}}
						            variant={"bodyM"}>{player.lastName}</Typography>
					</Stack>
				)}
		</Stack>
	)
}