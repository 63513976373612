import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {DesktopCompetitionPlayerStatistics} from "./DesktopCompetitionPlayerStatistics";
import {CompetitionStatisticsClubPlayersTable} from "./CompetitionStatisticsClubPlayersTable";

export function CompetitionPlayerStatistics() {
	const isMobile = useIsMobileContext();

	return isMobile ? <CompetitionStatisticsClubPlayersTable/>
		: <DesktopCompetitionPlayerStatistics/>
}


