import {Divider, Grid, Typography, useTheme} from "@mui/material";

import {useDevelopmentContext, useFooterColorMode, useIsMobileContext} from "@nitra/nkmb-card-components";
import {INNER_MAX_WIDTH} from "App";
import SponsorIcon from "./SponsorIcon";

const Footer = () => {
	const {isMonochrome} = useFooterColorMode()
	const theme = useTheme();

	const isMobile = useIsMobileContext();
	const {showFooterDivider} = useDevelopmentContext()

	const bigSponsors = [
		{logoFileName: "sava.png", link: "https://www.zav-sava.si/", name: "Zavarovalnica Sava"},
		{logoFileName: "adidas.png", link: "https://www.adidas.com/us", name: "Adidas"},
	];

	const smallSponsors = [
		{logoFileName: "lasko.png", link: "https://www.lasko.eu/", name: "Laško"},
		{logoFileName: "terme3000.png", link: "https://www.sava-hotels-resorts.com/si/", name: "Sava hotels & resorts"},
		{logoFileName: "mastercard.png", link: "https://www.mastercard.si/sl-si.html", name: "Mastercard"},
		{logoFileName: "TotiRadio.png", link: "https://totiradio.si/", name: "Toti radio"},
		{logoFileName: "PostaSlovenije.png", link: "https://www.posta.si/", name: "Pošta Slovenije"},
		{logoFileName: "mercator.png", link: "https://www.mercator.si/", name: "Mercator"},
		{logoFileName: "energijaplus.png", link: "https://www.energijaplus.si/", name: "Energija Plus"},
		{logoFileName: "nkbm.png", link: "https://www.nkbm.si/", name: "Nova KBM"},
	]

	return (
		<Grid
			container
			direction={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			sx={{
				minHeight: 313,
				backgroundColor: isMonochrome ? "" : theme.palette.background.default,
			}}
		>
			{showFooterDivider && <Divider sx={{width: "100%", maxWidth: INNER_MAX_WIDTH, mb: 4,}}/>}
			<Grid item container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={0}>
				{bigSponsors.map((sponsor, index) => (
					<Grid item key={index} xs={6} sm={4} md={3} lg={2} sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<SponsorIcon
							logoFileName={sponsor.logoFileName}
							link={sponsor.link}
							name={sponsor.name}
							isBig={true}
							useMonochrome={isMonochrome}/>
					</Grid>
				))}
			</Grid>
			<Grid item container direction={"row"} justifyContent={"center"} alignItems={"center"}>
				{smallSponsors.map((sponsor, index) => (
					<Grid item key={index} xs={3} md={1} sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<SponsorIcon
							logoFileName={sponsor.logoFileName}
							link={sponsor.link}
							name={sponsor.name}
							isBig={false}
							useMonochrome={isMonochrome}/>
					</Grid>
				))}
			</Grid>
			<Grid item container direction={"column"} justifyContent={"center"} alignItems={"center"}
			      sx={{color: isMonochrome ? "#FFF" : ""}}>
				<Typography variant={"podnapis"}>Uradna spletna stran Nogometnega Kluba
					Maribor</Typography>
				<Typography variant={"podnapis"}>vse pravice pridržane © NK Maribor,
					{(new Date()).getFullYear()}</Typography>
				<Typography variant={"podnapis"} sx={{mb: 2}}>Politika piškotkov</Typography>
			</Grid>
		</Grid>
	)
}

export default Footer;
