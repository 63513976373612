import {CardContent, Grid, Stack, Typography} from "@mui/material";
import {CardFooter, CardOverImageTitle, CardWithShadow, ECardHeight} from "@nitra/nkmb-card-components";
import {IconAndTextLineDesktop} from "./IconAndTextLineDesktop";
import {NkmbIconFax, NkmbIconLokacija, NkmbIconMail, NkmbIconTel} from "@nitra/nkmb-theme";
import {OpeningTimeDesktopBox} from "./OpeningTimeDesktopBox";
import React from "react";

export function MoreContactDesktop() {
	const leftCardSx = {width: "100%", my: 2};
	return (
		<Stack direction={"row"} sx={{}}>
			<Grid container sx={{maxWidth: "912px", py: 2}}>

				<CardWithShadow disableOnHoverActions={true} cardHeight={ECardHeight.UNSET} sx={leftCardSx}>
					<CardContent>
						<Typography variant={"podnaslovL"}>nogometni klub maribor</Typography>
						<Stack direction={"row"}>
							<Stack sx={{flexGrow: 0.9}}>
								<IconAndTextLineDesktop icon={NkmbIconTel} text={"info@nkmaribor.com"}/>
								<IconAndTextLineDesktop icon={NkmbIconLokacija}
								                        text={"Mladinska ulica 29, 2000 Maribor"}/>
							</Stack>
							<Stack sx={{flexGrow: 1}}>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02/22847 00"}/>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02/22847 01"}/>
								<IconAndTextLineDesktop icon={NkmbIconFax} text={"02/22847 11"}/>
							</Stack>
						</Stack>
					</CardContent>
				</CardWithShadow>

				<CardWithShadow disableOnHoverActions={true} cardHeight={ECardHeight.UNSET} sx={leftCardSx}>
					<CardContent>
						<Typography variant={"podnaslovL"} sx={{pb: 2}}>VIJOL'ČNA BAJTA</Typography>
						<Stack>
							<IconAndTextLineDesktop icon={NkmbIconTel} text={"trgovina@nkmaribor.com"}/>
						</Stack>
						<Stack direction={"row"} sx={{pt: 2}}>
							<Stack sx={{flexGrow: 0.9}}>
								<Typography sx={{pb: 2}} variant={"podnaslovS"}>Mercator center Tabor II</Typography>
								<IconAndTextLineDesktop icon={NkmbIconLokacija} text={"Ulica Eve Lovše 1"}/>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02/6209666"}/>
								<OpeningTimeDesktopBox days={[
									"PON - SOB",
									"NEDELJA, PRAZNIKI"
								]} times={[
									"9:00 - 19:00",
									"ZAPRTO"
								]}/>
							</Stack>
							<Stack sx={{flexGrow: 1}}>
								<Typography sx={{pb: 2}} variant={"podnaslovS"}>Center mesta</Typography>
								<IconAndTextLineDesktop icon={NkmbIconLokacija} text={"Tyrševa ulica 1"}/>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02/6208696"}/>
								<OpeningTimeDesktopBox days={[
									"PON - PET",
									"SOB",
									"NED, PRAZNIKI"
								]} times={[
									"9:00 - 19:00",
									"9:00 - 14:00",
									"ZAPRTO"
								]}/>
							</Stack>
						</Stack>
					</CardContent>
				</CardWithShadow>
				<CardWithShadow disableOnHoverActions={true} cardHeight={ECardHeight.UNSET} sx={leftCardSx}>
					<CardContent>
						<Typography variant={"podnaslovL"}>FRIZERSKA BAJTA</Typography>
						<Stack>
							<IconAndTextLineDesktop icon={NkmbIconTel} text={"salon@nkmaribor.com"}/>
							<IconAndTextLineDesktop icon={NkmbIconLokacija}
							                        text={"Mercator center Tabor II, Ulica Eve Lovše 1"}/>
							<IconAndTextLineDesktop icon={NkmbIconMail} text={"02/6209667"}/>
						</Stack>
						<Stack sx={{flexGrow: 1}}>

							<OpeningTimeDesktopBox days={[
								"PON - PET",
								"SOB",
								"NED, PRAZNIKI"
							]} times={[
								"9:00 - 19:00",
								"9:00 - 14:00",
								"ZAPRTO"
							]}/>
						</Stack>
					</CardContent>
				</CardWithShadow>
			</Grid>
			<Stack sx={{pl: 3, pt: 4}} spacing={3}>
				<CardWithShadow
					sx={{display: "flex", flexDirection: "column", position: "relative"}}
					onClickNavigateUrl={"https://www.vijolcnabajta.si/si.html"}
					fixedWidth={288}
					fullSizeImageUrl={"/images/more/contact/bajta.png"}
				>
					<CardContent sx={{zIndex: 1, mt: "auto"}}>
						<CardOverImageTitle title={"Vijol'čna bajta"} variant={"podnaslovM"}/>
					</CardContent>
					<CardFooter brightText={true} shareUrl={"https://www.vijolcnabajta.si/si.html"}/>

				</CardWithShadow>
				<CardWithShadow
					sx={{display: "flex", flexDirection: "column", position: "relative"}}

					fixedWidth={288}
					onClickNavigateUrl={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}
					fullSizeImageUrl={"/images/more/contact/frizer.png"}
				>
					<CardContent sx={{zIndex: 1, mt: "auto"}}>
						<CardOverImageTitle title={"Frizerska bajta"} variant={"podnaslovM"}/>
					</CardContent>
					<CardFooter brightText={true} shareUrl={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}/>
				</CardWithShadow>
			</Stack>
		</Stack>
	);
}