import {Box, Grid, SxProps, Typography, useTheme} from "@mui/material";
import {CardTimestamp, ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import {INNER_MAX_WIDTH} from "App";
import {PropsWithChildren, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {LiveTag} from "./LiveTag";


const Banner = (props: PropsWithChildren<{
	title?: string,
	imageUrl?: string,
	createdAt?: Date,
	subtitle?: string,
	onClickLink?: string,
	hide?: boolean,
	sx?: SxProps,
	isLeftAligned?: boolean,
	isForMatch?: boolean
}>) => {
	const {title, imageUrl, createdAt, subtitle, onClickLink, hide, sx, isLeftAligned, isForMatch} = props;

	const navigate = useNavigate();
	const isMobile = useIsMobileContext();
	const {isIos} = useIosContext()
	const theme = useTheme();


	const [imageUrlState, setImageUrlState] = useState(imageUrl ?? "/images/placeholder600x360.png");
	const [titleState, setTitleState] = useState(title);
	const [hidden, setHidden] = useState(hide ?? false);

	useEffect(() => {
		setImageUrlState(imageUrl ?? "/images/placeholder600x360.png");
		setTitleState(title);
		setHidden(hide ?? false);
	}, [title, imageUrl, hide])

	function isInternalLink(url: string) {
		const link = new URL(url);
		return link.hostname === window.location.hostname;
	}

	function handleClick() {
		if (onClickLink) {
			if (onClickLink.startsWith("/")) {
				navigate(onClickLink);
			} else if (isInternalLink(onClickLink)) {
				const onClickUrl = new URL(onClickLink);
				navigate(onClickUrl.pathname + onClickUrl.search);
			} else {
				window.open(onClickLink, "_blank");
			}
		}
	}

	return (
		<Box sx={{
			backgroundImage: `url(${imageUrlState}), url(/images/placeholder1440x680.png)`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			width: "100%",
			aspectRatio: {
				xs: "auto",
				sm: "1440 / 480",
			},
			maxHeight: hidden ? 0 : {
				sm: "480px",
				xs: "287px",
			},
			marginTop: hidden ? "-40px" : 0,
			height: {
				xs: "300px",
				sm: "100%",
			},
			position: "relative",
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-end",
			alignItems: "flex-end",
			transition: "all 0.25s linear",
			cursor: "pointer",
			...sx,
		}}
		     onClick={handleClick}
		>
			<div style={{
				backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0.8))",
				zIndex: 0,
				position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
				flexGrow: 1
			}}/>
			<Grid item
			      container
			      direction={"row"}
			      sx={{
				      width: "100%",
				      margin: "auto",
				      mb: 2,
				      zIndex: 1,
				      px: {
					      xs: 2,
					      md: 0,
				      },
				      maxWidth: {
					      xs: "100%",
					      sm: "624px",
					      md: "912px",
					      lg: INNER_MAX_WIDTH,
					      xl: INNER_MAX_WIDTH,
				      }
			      }}>
				<Grid item xs={12}>
					<Typography sx={{
						typography: {xs: "h3", sm: "h1"},
						color: "background.paper",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{!isForMatch && titleState}</Typography>
					{isForMatch && <LiveTag/>}
				</Grid>
				{subtitle && <Typography variant={"bodyL"} sx={{
					color: "background.paper",
				}}>{subtitle}</Typography>}
				<Grid item container direction={"row"} alignItems={"center"} sx={{
					mt: isMobile ? 2 : 1,
					mb: isMobile ? 0 : 2,
				}}>
					<Grid item sx={{display: {xs: "none", sm: "block"}}}>
						<Box sx={{mr: 2,}}>
							<ShareArea shareUrl={"/"} brightText={true}/>
						</Box>
					</Grid>
					<Grid item>
						{isForMatch ? <Typography
								sx={{color: theme.palette.background.default}}
								variant={isMobile ? "podnaslovS" : "podnaslovM"}
							>{titleState}</Typography>
							: createdAt && <CardTimestamp date={createdAt} brightText={true}/>}

					</Grid>
					<Grid item sx={{display: {xs: "block", sm: "none"}, ml: "auto"}}>
						<ShareArea shareUrl={"/"} brightText={true}/>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default Banner

