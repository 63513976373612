import {SupportStaffListFirestoreDocument} from "../../models";
import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import {SupportStaffDesktopCard, SupportStaffMobileCard, useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function SupportStaffCommonGroup(props: {
	group: {
		label: string,
		staff: SupportStaffListFirestoreDocument[]
	}
}) {
	const {isIos} = useIosContext()
	const theme = useTheme();
	const isMobile = useIsMobileContext()

	return (
		<Stack direction={"column"} sx={{width: "100%", pt: 4,}}>
			<Typography
				variant={"podnaslovXS"}
				sx={{
					pb: 2,
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>{props.group.label}</Typography>
			<Grid container spacing={2} justifyContent={"space-between"}>
				{props.group.staff.map((person, index) => (
					<Grid key={index} item
					      xs={6}
					      lg={4}
					      xl={3}
					      sx={{marginRight: index === props.group.staff.length - 1 ? "auto" : "unset",}}
					>
						<Box style={{
							maxWidth: isMobile ? "178px" : "331px",
							width: "100%",
							paddingTop: `min(100%, ${isMobile ? "178px" : "331px"})`,
							position: 'relative',
						}}>
							<Box
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									display: 'flex',
									justifyContent: 'center',
								}}>
								{isMobile ?
									<SupportStaffMobileCard staff={person}/>
									:
									<SupportStaffDesktopCard staff={person}/>
								}
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Stack>
	)
}