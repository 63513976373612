import {Stack, Typography, useTheme} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {INNER_MAX_WIDTH} from "../../App";
import {ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";


const LandingVpsBanner = () => {
	const navigate = useNavigate()

	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<div
			onClick={() => navigate("/hospitality")}
			style={{
				backgroundImage: "url(/images/hospitality/skybox/skybox_banner.png)",
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: "100%",
				height: isMobile ? "420px" : "480px",
				position: "relative",
				display: "flex",
				flexDirection: "column",
				cursor: "pointer",
			}}>
			<div style={{
				backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0.8))",
				zIndex: 0,
				position: "absolute", top: 0, left: 0, right: 0, bottom: 0
			}}/>
			<Stack
				justifyContent="flex-end"
				sx={{
					width: "100%",
					maxWidth: INNER_MAX_WIDTH,
					margin: "auto",
					mb: 5,
					flexGrow: 1,
					zIndex: 1
				}}
			>
				<Typography
					variant={isMobile ? "h3" : "h1"}
					sx={{
						mx: 2,
						color: "background.paper",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>NK Maribor 5*</Typography>
				<Stack
					direction={"row"}

					justifyContent={"space-between"}
					alignItems={"flex-end"}
				>
					<Typography variant={"bodyL"} sx={{
						mx: 2,
						color: "background.paper",
					}}>Ekskluzivno doživetje v Ljudskem vrtu</Typography>
					<ShareArea shareUrl={"/hospitality"} brightText={true}/>
				</Stack>
			</Stack>
		</div>
	)
}

export default LandingVpsBanner
