import {Stack} from "@mui/material";
import Banner from "components/Banner";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getStadiumLegendaryMatch} from "firestore/specificGetters/stadiumLegendaryMatches";
import {
	ContentElementPageCacheFirebaseDocumentUnion
} from "models/firestore/pageCache/article/ContentElementPageCacheFirebaseDocument";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export function ClubStadiumLegendaryMatch() {
	const {key} = useParams();

	const [title, setTitle] = useState<string>("");
	const [content, setContent] = useState<ContentElementPageCacheFirebaseDocumentUnion[]>([]);
	const [imageUrl, setImageUrl] = useState<string>("");

	const getDetails = () => {
		if (!key) return;

		getStadiumLegendaryMatch(key).then((payload) => {
			setTitle(payload.title);
			setContent(payload.content);
			setImageUrl(payload.image1440x680url);
		})
	}

	useEffect(() => {
		getDetails();
	}, [key])

	return (
		<Stack>
			<Banner title={title} imageUrl={imageUrl}/>
			<ContentContainer content={content}/>
		</Stack>
	);
}
