import {Stack} from "@mui/material";
import {
	ECompetitionType,
	StadiumLegendaryMatchCard,
	StadiumLegendaryMatchListFirestoreDocument
} from "@nitra/nkmb-card-components";
import {isEuroCompetitionType} from "@nitra/nkmb-card-components/enums/ECompetitionType";
import {CardContainerWithName} from "components/CardContainerWithName";
import {InnerWidthContainer} from "components/widthControlElements/InnerWidthContainer";
import {GetStadiumLegendaryMatches} from "firestore";
import React, {useEffect, useState} from "react";
import {ClubBanner} from "../../ClubBanner";
import {ClubStadiumTabNavigatorButtons} from "../ClubStadiumTabNavigatorButtons";

export function ClubStadiumLegendaryMatches() {
	const [matches, setMatches] = useState<StadiumLegendaryMatchListFirestoreDocument[]>([]);

	const fetchData = () => {
		GetStadiumLegendaryMatches().then((payload) => {
			setMatches(payload.matches);
		})
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<Stack sx={{m: 0, p: 0}}>
			<ClubBanner
				url={"/images/club/banners/stadium-legendary.png"}
				mainText={"Legendarne tekme"}
			/>
			<ClubStadiumTabNavigatorButtons/>
			<InnerWidthContainer>
				<CardContainerWithName title={"Prva liga"} cardAlignLeft={true}>
					{matches
						.filter(match => match.competition === ECompetitionType.FIRST_LEAGUE)
						.sort((a, b) => b.date.getTime() - a.date.getTime())
						.map((match, index) =>
							<StadiumLegendaryMatchCard key={index} data={match}/>
						)}
				</CardContainerWithName>
				<CardContainerWithName title={"Slovenski pokal "} cardAlignLeft={true}>
					{matches
						.filter(match => match.competition === ECompetitionType.CUP)
						.sort((a, b) => b.date.getTime() - a.date.getTime())
						.map((match, index) =>
							<StadiumLegendaryMatchCard key={index} data={match}/>
						)}
				</CardContainerWithName>
				<CardContainerWithName title={"Evropske tekme"} cardAlignLeft={true}>
					{matches
						.filter(match => isEuroCompetitionType(match.competition))
						.sort((a, b) => b.date.getTime() - a.date.getTime())
						.map((match, index) =>
							<StadiumLegendaryMatchCard key={index} data={match}/>
						)}
				</CardContainerWithName>
			</InnerWidthContainer>
		</Stack>
	)
}

