import {
	ArticleMatchCardFirestoreDocument,
	ArticleMatchGoalFirestoreDocument,
	ArticleMatchSubstitutionFirestoreDocument
} from "../../../../models";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {Stack, Typography, useTheme} from "@mui/material";
import {ArticleMatchEventLine} from "./ArticleMatchEventLine";

export function ArticleMatchClubEmblemNameAndEvents(props: {
	emblemUrl: string,
	teamName: string,
	events: (ArticleMatchGoalFirestoreDocument | ArticleMatchCardFirestoreDocument | ArticleMatchSubstitutionFirestoreDocument)[],
}) {
	const {emblemUrl, teamName, events} = props

	const isMobile = useIsMobileContext()
	const theme = useTheme()

	return (
		<Stack alignItems={"center"} sx={{color: theme.palette.background.default}}>
			<img style={{width: "120px", height: "120px"}} src={emblemUrl}
			     alt={teamName}/>
			<Typography sx={{mt: "-12px"}}
			            variant={isMobile ? "bodyNaslovBoldUppercase" : "bodyL"}>{teamName.toUpperCase()}</Typography>
			<Stack alignItems={"stretch"} spacing={1}>
				{
					events
						.sort((a, b) => a.minute - b.minute)
						.map(event =>
							<ArticleMatchEventLine key={event.key} event={event}/>
						)
				}
			</Stack>
		</Stack>
	)
}