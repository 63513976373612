import {ArticleMatchMetaDataFirestoreDocument} from "../../../../models";
import {Stack, SxProps, Typography, useTheme} from "@mui/material";
import {ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import {ArticleMatchStripBothTeamLineup} from "./ArticleMatchStripBothTeamLineup";
import {ArticleMatchCommonData} from "./ArticleMatchCommonData";

export function ArticleMatchStrip(props: {
	title: string,
	imageUrl: string,
	matchMetaData?: ArticleMatchMetaDataFirestoreDocument,
	sx?: SxProps,
}) {
	const {title, imageUrl, matchMetaData} = props;
	const isMobile = useIsMobileContext()
	const theme = useTheme()

	if (!matchMetaData) return null

	return (
		<Stack sx={{
			maxWidth: {
				xs: "100%",
				md: "348px",
			},
			backgroundColor: theme.palette.text.primary,
			...props.sx,
		}}>
			{isMobile &&
                <Stack
                    justifyContent={"flex-end"}
                    alignItems={"flex-start"}
                    sx={{
						position: "relative",
						height: "150px",
						backgroundImage: `url(${imageUrl})`,
						px: 2,
						py: 1
					}}>
                    <div style={{
						backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(18, 18, 18, 1.0)) 100%",
						zIndex: 0,
						position: "absolute", top: 0, left: 0, right: 0, bottom: 0
					}}/>
                    <Typography sx={{color: theme.palette.background.default, pb: 1, zIndex: 1,}}
                                variant={"h4"}>{title}</Typography>
                    <ShareArea brightText={true} sx={{ml: "unset", zIndex: 1}}/>
                </Stack>
			}
			<ArticleMatchCommonData matchMetaData={matchMetaData}/>
			<Typography sx={{
				color: theme.palette.secondary.main,
				pt: 10,
				pl: isMobile ? 6 : 1,
				width: "124px",
				textAlign: "center"
			}} variant={"bodyL"}>POSTAVE</Typography>
			<ArticleMatchStripBothTeamLineup matchMetaData={matchMetaData} isFirstEleven={true}/>
			<Typography sx={{
				color: theme.palette.background.paper,
				pt: 10,
				pl: isMobile ? 6 : 1,
				width: "124px",
				textAlign: "center"
			}} variant={"bodyL"}>REZERVE</Typography>
			<ArticleMatchStripBothTeamLineup matchMetaData={matchMetaData} isFirstEleven={false}/>
		</Stack>
	)
}

